.modal-dimmer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: 1;
    overflow: auto;
    text-align: center;
    padding: 0;
    .modal-inner {
        display: inline-block;
        text-align: left;
        margin: auto;
        width: 100%;
        &.with-border-radius {
            @media (min-width: 768px) {
                border-radius: 20px;
                overflow: hidden;
                box-shadow: 0px 10px 10px #00000054;
            }    
        }
        .close-btn {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;
            opacity: 0.5;
            font-size: 0;
            transition: opacity 0.2s ease;
            &:hover {
                opacity: 1;
            }
        }
    }    
    @media (min-width: 768px) {
        padding: 24px
    }
    &.modal-fade-enter {
        transition: all 0.3s ease;
        background-color: rgba(0,0,0,0);
        .modal-inner {
            transition: all 0.3s ease;
            opacity: 0;
            transform: translateY(30px);
        }
    }
    &.modal-fade-enter-active {
        background-color: rgba(0,0,0,0.6);
        .modal-inner {
            opacity: 1;
            transform: translateY(0);       
        }
    }
    &.modal-fade-enter-done {
        background-color: rgba(0,0,0,0.6);
        .modal-inner {
            transition: all 0.3s ease;
            opacity: 1;
            transform: translateY(0px);    
        }
    }
    &.modal-fade-exit {
        transition: all 0.3s ease;
        background-color: rgba(0,0,0,0.6);
        .modal-inner {
            transition: all 0.3s ease;
            opacity: 1;
            transform: translateY(0px);    
        }
    }
    &.modal-fade-exit-active {
        background-color: rgba(0,0,0,0);
        .modal-inner {
            opacity: 0;
            transform: translateY(-30px);    
        }
    }
    &.modal-fade-exit-done {
        background-color: rgba(0,0,0,0);
        .modal-inner {
            opacity: 0;
            transform: translateY(-30px);   
        }
    }
	.modal-content {
		& > hr {
			border: none;
			border-top: 2px solid #f4f4f4;
			margin: 20px -15px;
		}
	}
}