// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

#rainbow_modal {
    // background-color: #1a1a1a;
    height: 100%;
    // overflow-y: scroll;
    overflow-x: hidden;
    #rainbow_app {
        font-family: 'Raleway', sans-serif;
        box-sizing: border-box;
        font-size: 1rem;
        color: #333333;
        background-image: none;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto;
        // background-color: #F8F8F8;
        background-color: #fdfdfd;
        max-width: 1000px;
        // min-height: 100vh;
        margin: auto;
        position: relative;
        padding-bottom: 100px;
        @media only screen and (min-width: 376px) {
            & {
                background-image: url('./media/background_wide_faded_pale.png');
            }
        }
    }
	#rainbow-svg {
		height: 400px;
	}
    *, *:before, *:after {
        box-sizing: inherit;
        }


        h1, h2, h3, h4, h5, h6, p, ol, ul {
        margin: 0;
        padding: 0;
        }

        ol, ul {
        list-style: none;
        }

        img {
        max-width: 100%;
        height: auto;
        }

        a {
            text-decoration: none;
            color: #2562be;
        }

        .app_wrapper {
            max-width: 375px;
            padding: 0;
            position: relative;
            text-align: center;
            margin: 0 auto;
        }
        .header__title {
            width: 100%;
            padding: 15px 80px;
        }

        .title__container {
            display: flex;
            justify-content: center;
            padding-top: 2rem;
        }

        .logo {
            padding-right: 0.5rem;
        }

        .title {
            color: #333333;
            font-size: 2rem;
            line-height: 2.3rem;
            padding-left: 0.5rem;
        }

        .rainbow__container {
            display: flex;
            justify-content: center;
            overflow: hidden;
        }

        .total__raised {
            color: #ffffff;
            height: 6rem;
        }

        .total__raised h3 {
            font-size: 2.5rem;
            margin: 0;
        }

        .total__raised p {
            margin: 0;
        }

        .form-container {
            margin: auto;
            padding: 0 20px 100px 20px;
        }

        .form-header {
            font-size: 30px;
            margin: 10px 0px 30px 0px;
        }

        .donation-form {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 0;
            /* padding: 0 1rem; */
        }

        .success-message {
            font-family: inherit;
            background-color: #3f89f8;
            padding: 1rem;
            color: white;
        }

        .form-field {
            outline: none;
            text-align: left;
            margin: 0 0 0.5rem;
            padding: 0.72rem;
            font-size: 1rem;
            color: #333333;
            font-family: inherit;
            display: block;
            width: 100%;
        }

        button.form-field {
            text-align: center;
            transition: opacity 0.2s ease;
        }

        button:disabled.form-field {
            cursor: default;
            opacity: 0.4;
        }

        span {
            font-family: inherit;
            font-size: 14px;
            color: red;
            margin-bottom: 15px;
        }

        label.checkbox-label {
            margin: 10px 0px;
            display: auto;
            cursor: pointer;
        }

        input, textarea {
            background: #EFEFEF;
        }

        input::placeholder, textarea::placeholder {
            color: #999999;
        }

        input, textarea {
            border-radius: 50px;
            border: 2px solid #e5e5e5;
        }

        input:focus, textarea:focus {
            border: 2px solid #9b9b9b;
        }

        textarea {
            overflow-y: hidden;
            resize: none;
            border-radius: 12px;
            height: 8.45rem;
        }

        .full-name {
            margin-top: 0;
        }

        .error {
            border-style: solid;
            border: 2px solid #ffa4a4;
        }

        .submit {
            font-family: inherit;
            background: #333333;
            border-radius: 50px;
            color: white;
            cursor: pointer;
            font-weight: 400;
            font-size: 1.4rem;
            padding: 0.375rem;
            margin-top: 0.6rem;
        }

        input:disabled {
            cursor: default;
            color: #ffffff7c;
        }

        .recent_donation__container {
            color: #333333;
            padding-bottom: 16px;
        }

        .recent_donation__title {
            padding-top: 1.5rem;
            margin-bottom: 1.375rem;
            font-size: 1.375rem;
            font-weight: 400;
        }

        .recent_donation__card {
            /* margin-top: 20px; */
            /* padding: 40px 0 0; */
            text-align: center;
            padding-top: 0;
            padding-right: 2rem;
            padding-left: 2rem;
            margin: auto;
        }

        .recent_donation__card__content {
            background-color: #e8edee;
            border-radius: 12px;
            padding-bottom: 10px;
            /* padding-top: 10px; */
        }

        .recent_donation__name {
            margin: 0;
            font-size: 1.3rem;
            /* color: #0072ce; */
            color: #005eb8;
            /* margin-bottom: 10px; */
        }

        .recent_donation__message {
            /* padding-bottom: 10px; */
            margin: 0;
            color: #003087;
            /* color: #005eb8; */
        }

        .selection__pannel__container {
            position: absolute;
            overflow: hidden;
        }

        .selection__pannel {
            width: 100%;
            display: flex;
            padding: 0;
            margin: 0;
            justify-content: center;
        }

        .selection__pannel ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
        }

        .selection__pannel ul.hidden {
            display: none;
            padding: 0;
            margin: 0;
        }

        .selection__number {
            width: 40px;
            height: 20px;
            right: 5px;
            padding: 0;
            margin: 0;
        }

        .selection__pipe {
            width: 40px;
            height: 10px;
            font-size: 8px;
            right: 1px;
            padding: 0;
            margin: 0;
        }

        .selection__box {
            width: 52px;
            height: 52px;
            border: 1px solid rgba(255, 255, 255, 0.8);
            border-bottom: none;
            border-right: none;
            padding: 0;
            margin: 0;
        }

        .selected {
            background-color: white;
            opacity: .6;
            padding: 0;
            margin: 0;
        }

        .selection__pannel ul {
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);
            padding: 0;
            margin: 0;
        }

        .selection__pannel ul:last-child {
            border-right: 1px solid rgba(255, 255, 255, 0.8);
            padding: 0;
            margin: 0;
        }

        .nav_button {
            position: absolute;
            width: 32px;
            top: 156px;
            cursor: pointer;
        }

        .disabled {
            opacity: 0.5;
            cursor: default;
        }

        #rightNav {
            right: 0;
        }
        .rainbowPiece {
            cursor: pointer;
        }

        img.loading{
            display: block;
            position: absolute;
            top: 45%;
            transform: translate(-50%, -50%);
            left: 50%;
        }

        .rainbow_wrapper {
            position: relative;
        }

        .total_raised_wrap {
            color: #333333;
            color: #333333;
            position: relative;
            margin-bottom: 18px;
        }

        .total_raised {
            font-size: 50px;
            font-weight: 700;
        }

        .mb-1 {
            margin-bottom: 1rem;;
        }

	}
.v-middle > * {
	display: inline-block;
	vertical-align: middle;
}
